import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./root_reducer";

export const STORE_KEY = 'STORE_KEY';
export const STORE_TS = 'STORE_TS';
const MAX_AGE = 1000 * 60 * 60 * 24 * 7;
const DEBOUNCE_WRITE_INTERVAL = 1500;

export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;
export type RootStore = EnhancedStore<RootState, any>;

const stored = localStorage.getItem(STORE_KEY);
const age = stored && localStorage.getItem(STORE_TS) ? parseInt(window.localStorage.getItem(STORE_TS)!) : null;
let initialState: any = {};

if (stored && age && (Date.now() - age) < MAX_AGE) {
    initialState = JSON.parse(stored);
}

let store: RootStore = configureStore<any, any, any>({
    preloadedState: initialState,
    reducer: rootReducer,
});

function writeStoreIfNeeded(): void {
    const lastWrite = localStorage.getItem(STORE_TS) ? parseInt(window.localStorage.getItem(STORE_TS)!) : null;

    if (!lastWrite || ((Date.now() - lastWrite) > DEBOUNCE_WRITE_INTERVAL)) {
        const now = Date.now().toString();

        localStorage.setItem(STORE_TS, now);
        localStorage.setItem(STORE_KEY, JSON.stringify(store.getState()));
    }
}

store.subscribe(() => {
    writeStoreIfNeeded();
});

setInterval(() => {
    writeStoreIfNeeded();
}, 2_000);

export default store;
import { connect, ResolveThunks } from "react-redux";
import { rootSelectors, RootState } from "../../state/root_reducer";
import { actions as userActions } from "../../state/slices/user";

interface OwnProps {
    onSelect: (tag: Tag) => void;
    onDeselect: (tag: Tag) => void;
    enabledTags: Tag[];
    squareSize?: number | string;
    squareSpacing?: number | string;
}

interface StateProps {
    possibleTags: Tag[];
}

export type TagArrayProps = OwnProps & StateProps;

export function TagArray(props: TagArrayProps): JSX.Element {
    const squareStyle = (tag: Tag, enabled: boolean) => {
      const borderColor = tag === 'yellow' ? '#9ae6b4' : tag;
      return {
        width: props.squareSize || "1.75rem",
        height: props.squareSize || "1.75rem",
        backgroundColor: enabled ? tag : undefined,
        borderRadius: "0.25rem",
        borderWidth: 1,
        borderColor,
    }};
    
    function render(): JSX.Element {
        return (
            <div className="flex flex-row gap-x-2 items-center">
                {props.possibleTags.map((tag) => (
                    props.enabledTags.includes(tag) ?
                        <div key={`${tag}-clickable-square`} style={squareStyle(tag, true)} onClick={() => props.onDeselect(tag)}></div> :
                        <div key={`${tag}-clickable-square`} style={squareStyle(tag, false)} onClick={() => props.onSelect(tag)}></div>
                ))}
            </div>
        )
    }

    return render();
}

function mapState(state: RootState, ownProps: OwnProps): StateProps {
    return {
        possibleTags: state.user.possibleTags || [],
    }
}

export default connect(mapState) (TagArray);
import { connect, ResolveThunks } from "react-redux";
import { rootSelectors, RootState } from "../../state/root_reducer";
import SpotifyPlayer, { CallbackState } from 'react-spotify-web-playback';
import { actions as userActions, SongFeedbackAndSeed } from "../../state/slices/user";
import { actions as songActions } from "../../state/slices/songs";
import spotifyLogo from "./spotify-logo.png";
import vLogoNew from "./v-logo-new.png";
import { REDIRECT_URL } from "../route_master/routes";
import { useNavigate } from "react-router";
import { useState } from "react";
import TopNavBar from "../top_nav_bar";
import SongActionBar from "../song_action_bar";

interface OwnProps {
    loginUrl: string;
}

interface StateProps {
    spotifyUser: SpotifyUser | null; 
    token: string | null;
    tokenIsExpired: boolean;
    playlistUris: string[] | null;
    isPlaying: boolean;
    offset: number | undefined;
    currentTrackId: string | null;
    songFeedbackByGid: Record<string, SongFeedbackAndSeed> | null;
}

interface DispatchProps {
    onSetPlayingStatus: typeof songActions.setPlayingStatus;
    onAddFeedback: typeof userActions.addFeedback;
    onLogout: typeof userActions.logout;
}

export type SpotifyBarProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

function getTrackUri(song: Song): string {
    return `spotify:track:${song.spotify_id}`;
}

export function SpotifyBar(props: SpotifyBarProps): JSX.Element {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    function updatePlayingState(state: CallbackState): void {
        if (!state.isInitializing && state.isActive) {
            props.onSetPlayingStatus({isPlaying: state.isPlaying, playingNow: state.track});

            setLoaded(true);
        }

        if (props.currentTrackId && state.track.id && props.currentTrackId !== state.track.id) {
            console.log("#### DEBUG TRACK: CURRENT " + props.currentTrackId + " FROM CALLBACK: " + state.track.id);
            //window.location.reload();
        }
    }

    function render(): JSX.Element {
        return (
            <div className="w-full flex-grow-0 flex-shrink-0 flex flex-row" style={{zIndex:10, minHeight: 96, boxShadow: "0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 20%)", overflowX: 'hidden'}}>
                <div className="flex flex-col items-center content-center justify-center" style={{backgroundColor: 'rgba(75, 0, 75, 0.2)'}}>
                    <img className="object-contain md:object-scale-down m-1 sm:m-4 mr-5" src={vLogoNew} alt="V Logo" width="92" height="92"/>
                    <TopNavBar
                        url={window.location.pathname}/>
                </div>
                <div className="h-full w-full" style={{overflowX: 'hidden'}}>
                    {props.spotifyUser === null ? showLoginButton() : showSpotifyInfo()}
                </div>
            </div>
        )
    }

    function showLoginButton(): JSX.Element {
        return (
            <div className="absolute right-0 pr-5 pt-2">
                {!window.location.href.includes(REDIRECT_URL) && <button className="button-generic" onClick={() => window.location.replace(props.loginUrl)}>Login to Spotify</button>
                    }
            </div>
        );
    }

    function showSpotifyInfo(): JSX.Element {
        console.log("Spotify player playing: " + props.isPlaying);
        return (
            <div className="p-2 sm:p-4 flex flex-col pl-4 text-sm sm:text-md md:text-lg">
                {(props.playlistUris && props.token && !props.tokenIsExpired) &&
                    <div >
                        <SpotifyPlayer
                            token={props.token!}
                            uris={props.playlistUris} 
                            styles={{bgColor: '#f0deff'}}
                            offset={!loaded ? props.offset : undefined}
                            callback={(state) => updatePlayingState(state)}
                            play={loaded ? props.isPlaying : false}
                            />
                    </div>
                 }
                <div className="flex flex-row pt-2">
                    {props.currentTrackId &&
                        <SongActionBar
                            addBorder={true}
                            hasFeedback={!!props.songFeedbackByGid?.[props.currentTrackId]}
                            trackId={props.currentTrackId}
                            onAddFeedback={props.onAddFeedback}/>}
                    <div className="pr-2 flex flex-row items-center bordered" style={{marginLeft: 'auto'}}>
                        <img className="w-6 h-6 md:w-7 md:h-7" src={spotifyLogo} alt="Spotify Logo" width="30" height="30"/>
                        <span className="flex justify-center items-center ml-1 sm:ml-2 text-xs sm:text-sm md:text-base font-sans">{props.spotifyUser!.email}</span>
                        <span className="flex justify-center items-center ml-1 sm:ml-2 button-small cursor-pointer pr-1 pl-1 text-base"onClick={() => props.onLogout(navigate)}>⎋</span>
                    </div>
                </div>
            </div>
        );
    }

    return render();
}

function mapState(state: RootState): StateProps {
    return {
        spotifyUser: state.user.currentUser,
        token: state.user.accessToken,
        tokenIsExpired: rootSelectors.user.tokenIsExpired(state),
        playlistUris: rootSelectors.songs.getPlaylist(state)?.map((s) => getTrackUri(s)) || null,
        isPlaying: state.songs.isPlaying,
        offset: state.songs.offset ? state.songs.offset : undefined,
        currentTrackId: state.songs.currentTrackId,
        songFeedbackByGid: rootSelectors.user.getSongFeedbackByGid(state),
    }
}

const mapDispatch: DispatchProps = {
    onSetPlayingStatus: songActions.setPlayingStatus,
    onAddFeedback: userActions.addFeedback,
    onLogout: userActions.logout,
}

export default connect(mapState, mapDispatch) (SpotifyBar);
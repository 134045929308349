import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../root_reducer';
import { AppThunk, STORE_KEY, STORE_TS } from '../../store';
import request from 'superagent';
import { NavigateFunction } from 'react-router';
import { KNOWN_ROUTES } from '../../../components/route_master/routes';
import { API_HOST } from '../../../var';

const SPOTIFY_CURRENT_USER_ENDPOINT = 'https://api.spotify.com/v1/me';

export type SongFeedbackAndSeed = SongFeedback & {seed: boolean};

interface UserState {
    currentUser: SpotifyUser | null;
    code: string | null;
    accessToken: string | null;
    refreshToken: string | null;
    expirationTime: number | null;
    songFeedbackByGid: Record<string, SongFeedbackAndSeed> | null;
    debugState: string | null;
    possibleTags: Tag[];
}

const initialState: UserState = { currentUser: null, code: null, accessToken: null, refreshToken: null, expirationTime: null, songFeedbackByGid: null, debugState: null,
  possibleTags: ["red", "orange", "yellow", "green", "blue", "purple"]};

const songsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receivedCode(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
    receivedTokens(state, action: PayloadAction<ApiAuthTokenResponse>) {
      if (action.payload.refresh_token) {
        state.refreshToken = action.payload.refresh_token;
      }

      state.accessToken = action.payload.access_token;
      state.expirationTime = Date.now() + ((action.payload.expires_in - 120) * 1000);
    },
    receivedUserData(state, action: PayloadAction<SpotifyUser>) {
      state.currentUser = action.payload;
    },
    receivedSongFeedback(state, action: PayloadAction<SongFeedback[]>) {
      const oldSeedState: Record<string, SongFeedbackAndSeed> = {};
      let defaultForNewSongs = true;

      if (state.songFeedbackByGid) {
        for (const [gid, feedback] of Object.entries(state.songFeedbackByGid)) {
          oldSeedState[gid] = feedback;

          if (!feedback.seed) {
            defaultForNewSongs = false;
          }
        }
      }

      console.log('default for new feedback is: ' + defaultForNewSongs);

      state.songFeedbackByGid = {};

      for (const feedback of action.payload) {
        const updateToSeed = feedback.feedback === "Seed" && defaultForNewSongs;

        state.songFeedbackByGid[feedback.song.gid] = {
          ...feedback,
          seed: oldSeedState[feedback.song.gid]?.seed ?? updateToSeed,
        }

        console.log(`Updated seed state for ${feedback.song.name} to ${state.songFeedbackByGid[feedback.song.gid].seed} (default ${updateToSeed})`)
        delete oldSeedState[feedback.song.gid];
      }

      for (const [gid, feedback] of Object.entries(oldSeedState)) {
        console.log(`Detecting possibly missing feedback for ${gid} (${feedback.song.name})`);
      }
    },
    updateSeedStatus(state, action: PayloadAction<Song[]>) {
      const selectedGids = action.payload.map((s) => s.gid);

      if (state.songFeedbackByGid) {
        for (const [gid, entry] of Object.entries(state.songFeedbackByGid)) {
          entry.seed = selectedGids.includes(gid);
        }
      }
    },
    updateDebug(state, action: PayloadAction<string>) {
      state.debugState = action.payload;
    },
  },
});

export const actions = {
  ...songsSlice.actions,
  completeOauthFlow(code: string, refresh: boolean, navigate?: NavigateFunction): AppThunk {
    return async (dispatch, _getState) => {
      let cleanedResponse: ApiAuthTokenResponse;

      try {
        dispatch(actions.receivedCode(code));

        console.log(`Finishing oauth login... making request to ${API_HOST}/v1/complete_oauth?code=${code}&refresh=${refresh}`);

        const response = await request
          .get(`${API_HOST}/v1/complete_oauth?code=${code}&refresh=${refresh}`)
          .set('accept', 'application/json');

        cleanedResponse = JSON.parse(response.text);

        console.log('Fetched access token: ' + JSON.stringify(response.text));

        if (!cleanedResponse.failure) {
          dispatch(actions.receivedTokens(cleanedResponse));

          console.log('USING token: ' + cleanedResponse.access_token);
  
          const userProfileResponse = await request
            .get(SPOTIFY_CURRENT_USER_ENDPOINT)
            .set('Authorization', 'Bearer ' + cleanedResponse.access_token)
            .set('accept', 'application/json');
  
          console.log('GOT USER DATA: ' + userProfileResponse.text);
  
          dispatch(actions.receivedUserData(JSON.parse(userProfileResponse.text)));
  
          if (navigate) {
            navigate(KNOWN_ROUTES[0]);
          }
        } else {
          console.log("OAUTH FAILURE");
        }

        
      } catch (e) {
        console.error(e);

        return null;
      }
    };
  },
  addFeedback(songGid: string, feedback: FeedbackType): AppThunk {
    return async (dispatch, _getState) => {
      const userId = _getState().user.currentUser?.id;

      if (!userId) {
        console.log('No userid found - skipping feedback!');

        return;
      }

      let cleanedResponse: SongFeedback[];

      for (let i = 0; i < 5; i++) {

        try {
          const response = await request
            .get(`${API_HOST}/v1/user/${userId}/feedback/${songGid}?search=true&feedback=${feedback}`)
            .set('accept', 'application/json');
            
          cleanedResponse = JSON.parse(response.text);

          dispatch(actions.receivedSongFeedback(cleanedResponse));

          return null;
        } catch (e) {
          console.error(e);
          await new Promise<void>((resolve) => {
            setTimeout(() => resolve, 1_000);
          });
        }
      }

    }
  },
  removeFeedback(songGid: string): AppThunk {
    return async (dispatch, _getState) => {
      const userId = _getState().user.currentUser?.id;

      if (!userId) {
        console.log('No userid found - skipping deleting feedback!');

        return;
      }

      let cleanedResponse: SongFeedback[];

      try {
        const response = await request
          .get(`${API_HOST}/v1/user/${userId}/feedback/delete/${songGid}`)
          .set('accept', 'application/json');
          
        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongFeedback(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }
    }
  },
  addTag(songGid: string, tag: Tag): AppThunk {
    return async (dispatch, _getState) => {
      const userId = _getState().user.currentUser?.id;

      if (!userId) {
        console.log('No userid found - skipping tag!');

        return;
      }

      let cleanedResponse: SongFeedback[];

      try {
        const response = await request
          .get(`${API_HOST}/v1/user/${userId}/tag/${songGid}?remove=false&tag=${tag}`)
          .set('accept', 'application/json');
          
        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongFeedback(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }
    }
  },
  removeTag(songGid: string, tag: Tag): AppThunk {
    return async (dispatch, _getState) => {
      const userId = _getState().user.currentUser?.id;

      if (!userId) {
        console.log('No userid found - skipping removing tag!');

        return;
      }

      let cleanedResponse: SongFeedback[];

      try {
        const response = await request
          .get(`${API_HOST}/v1/user/${userId}/tag/${songGid}?remove=true&tag=${tag}`)
          .set('accept', 'application/json');
          
        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongFeedback(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }
    }
  },
  fetchSongFeedback(): AppThunk {
    return async (dispatch, _getState) => {
      const userId = _getState().user.currentUser?.id;

      if (!userId) {
        console.log('No userid found - skipping fetching feedback!');

        return;
      }

      let cleanedResponse: SongFeedback[];

      try {
        console.log('Fetching song feedback from server...');

        const response = await request
          .get(`${API_HOST}/v1/user/${userId}/feedback`)
          .set('accept', 'application/json');

        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongFeedback(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }
    };
  },
  fetchDebug(): AppThunk  {
    return async (dispatch, _getState) => {
      const response = await request
        .get(`${API_HOST}/v1/debug`)
        .set('accept', 'application/json');

        dispatch(actions.updateDebug(response.text));
    };
  },
  logout(navigate: (to: string) => void): AppThunk {
    return async (_, _getState) => {
      localStorage.removeItem(STORE_KEY);
      localStorage.removeItem(STORE_TS);

      navigate('/');
      window.location.reload();
    }
  },
  archiveUser(userId: string, navigate: (to: string) => void): AppThunk {
    return async (dispatch, _getState) => {
      console.log(`Archiving user.... ${userId}`);
      const response = await request
          .post(`${API_HOST}/v1/user/${userId}/archive`)
          .set('accept', 'application/json');
      if (response.statusCode === 200) {
        dispatch(actions.logout(navigate));
      } else {
        console.log(`Failed to log out: ${response.statusCode}`);
      }
    }
  }
}

const getSeedCandidateHelper = (state: RootState) => {
  if (state.user.songFeedbackByGid) {
    return Object.entries(state.user.songFeedbackByGid).flatMap(([_, songInfo]) => {
      if (songInfo.feedback === "Seed") {
        return songInfo;
      }

      return [];
    })
  } else {
    return [];
  }
}

export const selectors = {
    getCurrentUser(state: RootState): SpotifyUser | null {
      return state.user.currentUser;
    },
    tokenIsExpired(state: RootState): boolean {
      return state.user.expirationTime ? Date.now() > state.user.expirationTime : false;
    },
    getActiveSeeds(state: RootState): SongFeedbackAndSeed[] {
      return getSeedCandidateHelper(state).filter((sc) => sc.seed === true);
    },
    getSeedCandidates(state: RootState): SongFeedbackAndSeed[] {
      return getSeedCandidateHelper(state);
    },
    getSongFeedbackByGid(state: RootState): Record<string, SongFeedbackAndSeed> | null {
      return state.user.songFeedbackByGid;
    },
    getEnabledTags(state: RootState, songGid: string): Tag[] {
      return state.user.songFeedbackByGid?.[songGid]?.tags || [];
    },
    getEnabledTagsForSong(state: RootState): (songGid: string) => Tag[] {
      return (songGid: string) => state.user.songFeedbackByGid?.[songGid]?.tags || [];
    },
    getSeedsWithTags(state: RootState): (tag: Tag) => SongFeedbackAndSeed[] {
      return (tag: Tag) => getSeedCandidateHelper(state).filter((sc) => sc.tags.includes(tag));
    }
};

export default songsSlice.reducer
import { connect, ResolveThunks } from "react-redux";
import { RootState } from "../../state/root_reducer";
import { actions } from "../../state/slices/songs";
import SongListItem from "./item";

interface OwnProps {
    songs: Song[];
    otherdata?: (string | JSX.Element)[];
    otherdataName?: string;
    selectSongHandler: (song: Song) => void;
    deleteSongHandler?: (song: Song) => void;
    overridePlayHandler?: (song: Song, selected: boolean) => void;
    selectedSongsByGid?: Record<string, boolean>;
    maxSongs?: number;
}

interface StateProps {
    currentlyPlayingTrack: string | null;
}

interface DispatchProps {
    onAddToPlaylist: typeof actions.addToPlaylist;
}

export type SongListProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

export function SongList(props: SongListProps): JSX.Element {
    const max = props.maxSongs || 100;
    function render(): JSX.Element {
        return (
            <div className="flex flex-col" style={{overflowY: 'hidden'}}>
                {renderHeader(props.deleteSongHandler !== undefined)}
                <div className="h-full bg-white" style={{overflowY: 'auto'}}>
                    {props.songs.slice(0, max).map((song, index) => 
                      renderSong(song, song.gid === props.currentlyPlayingTrack, index, props.otherdata ? props.otherdata[index] : undefined, props.selectedSongsByGid ? props.selectedSongsByGid[song.gid] : true))}
                    {props.songs.length > max && 
                        <span className="text-xs sm:text-sm md:text-base text-left font-bold block text-gray-700 font-sans">{props.songs.length - max} More Songs...</span>}
                </div>
            </div>
        );
    }

    function renderHeader(includeSecondButton: boolean): JSX.Element {
        const cols = props.otherdata ? 4 : 3;
        
        return (
            <div className={`grid grid-cols-${cols} m-x-1 border rounded pl-1 border-gray-700`}>
                <div className="col-start-1 ml-1 pr-1 flex items-center">
                    <span className="text-xs sm:text-sm md:text-base text-left font-bold block text-gray-700 font-sans">Name</span>
                </div>
                <div className="col-start-2 pr-1 flex items-center">
                    <span className="text-xs sm:text-sm md:text-base text-left font-bold block text-gray-700 font-sans">Arist(s)</span>
                </div>
                <div className="col-start-3 pr-1 flex items-center">
                    <span className="text-xs sm:text-sm md:text-base text-left font-bold block text-gray-700 font-sans">Duration</span>
                </div>
                {props.otherdata &&
                    <div className="col-start-4 pr-1 flex items-center">
                        <span className="text-xs sm:text-sm md:text-base text-left font-bold block text-gray-700 font-sans">{props.otherdataName}</span>
                    </div>
                 }
                 <span className={`col-start-5 right-0 text-right block text-gray-700 text-bold pr-2 mb-1`}>
                    {includeSecondButton && 
                        <span className="invisible text-xs sm:text-sm md:text-base border border-black rounded pl-1 pr-1 button-small mr-2">
                            🗑
                        </span>
                        }
                    <span className="invisible text-xs sm:text-sm md:text-base border border-black rounded pl-1 pr-1 button-small">
                            ▶
                    </span>
                </span>
            </div>
        );
    }

    function renderSong(song: Song, playing: boolean, index: number, otherdata?: string | JSX.Element, selected?: boolean): JSX.Element  {
        return (
            <SongListItem 
                song={song}
                otherdata={otherdata}
                index={index}
                selectSongHandler={props.selectSongHandler}
                deleteSongHandler={props.deleteSongHandler}
                overridePlayHandler={props.overridePlayHandler}
                addStartingAtSong={(song) => {
                    const start = props.songs.findIndex((s) => s.gid === song.gid);
                    props.onAddToPlaylist(props.songs.slice(start));
                }}
                key = {song.gid}
                currentlyPlaying={playing}
                currentlySelected={selected}/>
        )
    }

    return render();
}

function mapState(state: RootState): StateProps {
    return {
        currentlyPlayingTrack: state.songs.currentTrackId,
    }
}

const mapDispatch: DispatchProps = {
    onAddToPlaylist: actions.addToPlaylist,
}

export default connect(mapState, mapDispatch)(SongList);
import { useState } from "react";
import { connect, ResolveThunks } from "react-redux";
import { rootSelectors, RootState } from "../../state/root_reducer";
import { actions as userActions } from "../../state/slices/user";
import { SongFeedbackAndSeed } from "../../state/slices/user";
import SongList from "../song_list";
import TagArray from "../tag_array";

interface OwnProps {
    onSaveSeeds: (seeds: Song[]) => void;
    onCancel: () => void;
}

interface StateProps {
    seeds: SongFeedbackAndSeed[];
    getSeedsWithTag: (tag: Tag) => SongFeedbackAndSeed[];
    getEnabledTagsForSong: (songGid: string) => Tag[]
}

interface DispatchProps {
    onAddTag: typeof userActions.addTag;
    onRemoveTag: typeof userActions.removeTag;
}

export type SeedsModalProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

function SeedsModal(props: SeedsModalProps): JSX.Element {
    const [selectedSongsByGid, setSelectedSongsByGid] = useState<Record<string, boolean>>(() => {
        const map: Record<string, boolean> = {};

        for (const seed of props.seeds) {
            map[seed.song.gid] = seed.seed;
        }

        return map;
    });

    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

    function getSelectedSongs(): Song[] {
        return props.seeds
            .filter((sr) => selectedSongsByGid[sr.song.gid])
            .map((sr) => sr.song);
    }

    function getSongsForTag(tag: Tag, selected: boolean) {
        const songs: Record<string, boolean> = {};

        for (const song of props.getSeedsWithTag(tag)) {
            songs[song.song.gid] = selected;
        }

        console.log("UPDATED SONGS " + JSON.stringify(songs));

        return songs;
    }

    function selectTag(tag: Tag): void {
        if (!selectedTags.includes(tag)) {
            const newTags = [...selectedTags];
            newTags.push(tag);
            setSelectedTags(newTags);
        }
        setSelectedSongsByGid({
            ...selectedSongsByGid,
            ...(getSongsForTag(tag, true)),
        })
    }

    function deSelectTag(tag: Tag): void {
        setSelectedTags(selectedTags.filter((t) => t !== tag));
        setSelectedSongsByGid({
            ...selectedSongsByGid,
            ...(getSongsForTag(tag, false)),
        })
    }

    function render(): JSX.Element {
        return (
            <div className="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">

                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <div className="flex flex-row align-center">
                                    <h3 className="sm:ml-2 text-lg leading-6 font-medium text-gray-900 font-sans flex items-center" id="modal-title">
                                        Select Seeds
                                    </h3>
                                    <button type="button" className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-3 w-auto"
                                        onClick={() => {
                                            const value = {
                                                ...selectedSongsByGid
                                            };

                                            for (const key of Object.keys(selectedSongsByGid)) {
                                                value[key] = false;
                                            }

                                            setSelectedSongsByGid(value);
                                        }}>
                                        None
                                    </button>
                                    <button type="button" className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-3 w-auto mr-3"
                                        onClick={() => {
                                            const value = {
                                                ...selectedSongsByGid
                                            };

                                            for (const key of Object.keys(selectedSongsByGid)) {
                                                value[key] = true;
                                            }

                                            setSelectedSongsByGid(value);
                                        }}>
                                        All
                                    </button>
                                    <TagArray onDeselect={deSelectTag} onSelect={selectTag} enabledTags={selectedTags}/>
                                </div>
                                <div className="mt-2">
                                <SongList
                                    maxSongs={1000} 
                                    songs={props.seeds.map((s) => s.song)}
                                    selectSongHandler={() => {}}
                                    overridePlayHandler={(song, selected) => {
                                        const value = {
                                            ...selectedSongsByGid
                                        };

                                        value[song.gid] = selected;

                                        setSelectedSongsByGid(value);
                                    }}
                                    selectedSongsByGid={selectedSongsByGid}
                                    otherdataName="Tags"
                                    otherdata={props.seeds.map((s) => (
                                        <TagArray
                                          enabledTags={props.getEnabledTagsForSong(s.song.gid)}
                                          squareSize="1rem"
                                          onSelect={(tag) => props.onAddTag(s.song.gid, tag)} 
                                          onDeselect={(tag) => props.onRemoveTag(s.song.gid, tag)}/>
                                    ))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => props.onSaveSeeds(getSelectedSongs())}>
                            Save
                        </button>
                        <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => props.onCancel()}>
                            Cancel
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}

function mapState(rootState: RootState): StateProps {
    return {
        seeds: rootSelectors.user.getSeedCandidates(rootState),
        getSeedsWithTag: rootSelectors.user.getSeedsWithTags(rootState),
        getEnabledTagsForSong: rootSelectors.user.getEnabledTagsForSong(rootState),
    }
}

const mapDispatch: DispatchProps = {
    onAddTag: userActions.addTag,
    onRemoveTag: userActions.removeTag,
}

export default connect(mapState, mapDispatch)(SeedsModal);
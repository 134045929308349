import './loading.css'

export interface LoadingSpinnerProps {
    border: boolean;
}

export function LoadingSpinner(props: LoadingSpinnerProps): JSX.Element {
    return (
        <div className={"w-full h-full flex flex-col pt-32 " + (props.border ? "border-t border-black" : "")} style={{justifyContent: 'center', alignItems: 'center'}}>
            <div className="lds-dual-ring"></div>
        </div>
    );
    
}
import { combineReducers } from '@reduxjs/toolkit';
import songsReducer, { selectors as songsSelectors } from './slices/songs';
import userReducer, { selectors as userSelectors } from './slices/user';

const reducer = combineReducers({
    songs: songsReducer,
    user: userReducer,
});

export type RootState = ReturnType<typeof reducer>;

export const rootSelectors = {
    songs: songsSelectors,
    user: userSelectors,
}

export default reducer;
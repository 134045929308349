import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../root_reducer';
import store, { AppThunk } from '../../store';
import request from 'superagent';
import { API_HOST } from '../../../var';
import { SpotifyPlayerTrack } from 'react-spotify-web-playback/lib';
import { SongFeedbackAndSeed } from '../user';

const SPOTIFY_TRACK_ENDPOINT = 'https://api.spotify.com/v1/tracks/';

interface SongsState {
    selectedSongHistory: Song[];
    selectedSongArtUri: string | null;
    selectedSongSpotifyUri: string | null;
    playlist: Song[] | null;
    offset: number | null;
    isPlaying: boolean;
    currentTrackId: string | null;
    songsByGid: Record<string, Song>;
    songRecsByGid: Record<string, SongRecommendation[]>;
    currentSearch: string;
    searchResults: Song[] | null;
    songRecsFromLikes: SongRecommendation[] | null;
}

const initialState: SongsState = { selectedSongHistory: [], selectedSongArtUri: null, selectedSongSpotifyUri: null, playlist: null, offset: null, isPlaying: false, currentTrackId: null, songsByGid: {}, songRecsByGid: {}, currentSearch: '', searchResults: null, songRecsFromLikes: null };

const songsSlice = createSlice({
  name: 'songs',
  initialState,
  reducers: {
    receivedSongs(state, action: PayloadAction<Song[]>) {
      action.payload.forEach((song) => {
        if (!state.songsByGid[song.gid]) {
          state.songsByGid[song.gid] = song;
        }
      });

      console.log(`Done loading ${action.payload.length} songs.`)
    },
    receivedSearchResult(state, action: PayloadAction<Song[] | null>) {
      state.searchResults = action.payload;
    },
    searchStringUpdated(state, action: PayloadAction<string>) {
      state.currentSearch = action.payload;
    },
    receivedRecommendations(state, action: PayloadAction<{forSong: Song, recs: SongRecommendation[]}>) {
      const {forSong, recs} = action.payload;

      state.songRecsByGid[forSong.gid] = recs;
    },
    receivedRecommendationsFromLikes(state, action: PayloadAction<SongRecommendation[] | null>) {
      state.songRecsFromLikes = action.payload;
    },
    pushSelectedSong(state, action: PayloadAction<{song: Song, selectedSongArtUri: string | null, spotifyUri: string | null}>) {
      state.selectedSongHistory.push(action.payload.song);
      state.selectedSongArtUri = action.payload.selectedSongArtUri;
      state.selectedSongSpotifyUri = action.payload.spotifyUri;

      if (state.playlist === null) {
        state.playlist = [action.payload.song];
      }
    },
    popSelectedSong(state, action: PayloadAction<{spotifyUri: string | null, selectedSongArtUri: string | null}>) {
      state.selectedSongHistory.pop();
      state.selectedSongArtUri = action.payload.selectedSongArtUri;
      state.selectedSongSpotifyUri = action.payload.spotifyUri;
    },
    addToPlaylist(state, action: PayloadAction<Song[]>) {
      if (state.playlist === null) {
        state.playlist = [];
      }

      state.playlist = action.payload.filter((s) => s.spotify_id);
      state.isPlaying = true;
      state.offset = 0;
      state.currentTrackId = state.playlist[0].gid;

      console.log('Playing is now: ' + state.playlist.map((s) => s.name).join(','));
      console.log('Playing : ' + state.isPlaying);
      
    },
    setPlayingStatus(state, action: PayloadAction<{isPlaying: boolean, playingNow: SpotifyPlayerTrack}>) {
      const {isPlaying, playingNow} = action.payload;

      state.isPlaying = isPlaying;

      let playing = state.playlist?.findIndex((s) => s.spotify_id === playingNow.id);

      if (playing === -1) {
        console.log(`Cant find matching spotify ID, searching... in ${JSON.stringify(state.playlist?.map((s) => s.name))}`);

        playing = state.playlist?.findIndex((s) => playingNow.name.toLowerCase().includes(s.name.toLowerCase()) || s.name.toLowerCase().includes(playingNow.name.toLowerCase()));
      }

      if (playing !== undefined && playing !== -1 && state.playlist) {
        state.offset = playing;
        state.currentTrackId = state.playlist[playing].gid;
        console.log(`Setting currentTrackId to ${state.currentTrackId} (${state.playlist[playing].name})`);
      }
    },
  },
});

function getGids(source: Record<string, SongFeedbackAndSeed>): string {
  const seeds: string[] = [];

  for (const [gid, sr] of Object.entries(source)) {
    if (sr.seed) {
      seeds.push(gid);
    }
  }

  return seeds.join(',');
}

async function fetchArtUri(song: Song, token: string): Promise<{albumArtUrl: string | null; trackUrl: string | null}> {
  try {
    const trackResponse = await request
      .get(SPOTIFY_TRACK_ENDPOINT + song.spotify_id)
      .set('Authorization', 'Bearer ' + token)
      .set('accept', 'application/json');
    const result = JSON.parse(trackResponse.text);

    return {albumArtUrl: result?.album?.images[0]?.url || null, trackUrl: result?.external_urls?.spotify || null};
  } catch (e) {
    console.error(e);

    return {albumArtUrl: null, trackUrl: null};
  }
}

export const actions = {
  ...songsSlice.actions,
  fetchAllSongs(): AppThunk {
    return async (dispatch, _getState) => {
      let cleanedResponse: Song[];

      try {
        console.log(`Platform ${process.env.NODE_ENV} resolved to API host ${API_HOST}`);

        console.log('Fetching ALL songs from server...');

        const response = await request
          .get(`${API_HOST}/v1/songs`)
          .set('accept', 'application/json');

        cleanedResponse = JSON.parse(response.text);

        console.log(`${cleanedResponse.length} songs fetched. Loading into store...`);

        dispatch(actions.receivedSongs(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }
    };
  },
  setSearchString(searchString: string, callback: () => void): AppThunk {
    return async (dispatch, _getState) => {
      console.log('Set search string to: ' + searchString);

      let cleanedResponse: Song[];

      try {
        if (searchString.trim().length === 0) {
          console.log('Fetching some songs');
  
          const response = await request
            .get(`${API_HOST}/v1/songs`)
            .set('accept', 'application/json');

          cleanedResponse = JSON.parse(response.text);
        } else {
          console.log('Searching for some songs matching: ' + searchString);
  
          const response = await request
            .get(`${API_HOST}/v1/songs?search=${encodeURIComponent(searchString)}`)
            .set('accept', 'application/json');

          cleanedResponse = JSON.parse(response.text);
  
        }

        console.log(`${cleanedResponse.length} songs fetched. Loading into store...`);

        dispatch(actions.receivedSongs(cleanedResponse));
        dispatch(actions.receivedSearchResult(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }

      dispatch(actions.searchStringUpdated(searchString));

      console.log('Done updating search string');

      callback();
    }
  },
  fetchRecommendations(song: Song, search: boolean): AppThunk {
    return async (dispatch, _getState) => {
      let cleanedResponse: SongRecommendation[];

      console.log(`Fetching recs for ${song.name} (${song.gid})`);

      const recCount = 250;

      try {
        const response = await request
          .get(`${API_HOST}/v1/songs/recommendations/${song.gid}?search=${search}&filterSameArtist=true&recCount=${recCount}`)
          .set('accept', 'application/json');

        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongs(cleanedResponse.map((sr) => sr.song)));
        dispatch(actions.receivedRecommendations({forSong: song, recs: cleanedResponse}));
      } catch (e) {
        console.error(e);

        return null;
      }
    }
  },
  fetchRecommendationsFromLikes(numItems: number, depth: number, top: boolean, includeSeeds: boolean, includeLikes: boolean, popPenalty: number): AppThunk {
    return async (dispatch, _getState) => {
      const userId = _getState().user.currentUser?.id;

      if (!userId) {
        console.log(`User not logged in - can't generate recs from likes.`);
      }

      let cleanedResponse: SongRecommendation[];

      console.log(`Fetching recs from likes for ${userId}...`);

      try {
        const seeds = _getState().user.songFeedbackByGid;

        const response = await request
          .get(`${API_HOST}/v1/user/${userId}/recommendations?numItems=${numItems}&depth=${depth}&top=${top}&popPenalty=${popPenalty}&includeSeeds=${includeSeeds}&includeLikes=${includeLikes}${seeds ? `&seeds=${getGids(seeds)}` : ''}`)
          .set('accept', 'application/json');

        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongs(cleanedResponse.map((sr) => sr.song)));
        dispatch(actions.receivedRecommendationsFromLikes(cleanedResponse));
      } catch (e) {
        console.error(e);

        return null;
      }
    }
  },
  clearRecommendationsFromLikes(): AppThunk {
    return async (dispatch, _) => {
      dispatch(actions.receivedRecommendationsFromLikes(null));
    }
  },
  searchForMoreResults(callback: () => void): AppThunk {
    return async (dispatch, _getState) => {
      let cleanedResponse: Song[];

      try {
        console.log(`Attempting to search for: ${_getState().songs.currentSearch}`);

        const response = await request
          .post(`${API_HOST}/v1/add_search`)
          .set('accept', 'application/json')
          .field('searchTerm', _getState().songs.currentSearch)

        cleanedResponse = JSON.parse(response.text);

        dispatch(actions.receivedSongs(cleanedResponse));
        dispatch(actions.setSearchString(_getState().songs.currentSearch, callback))
      } catch (e) {
        console.error(e);

        return null;
      }
    }
  },
  updateSelectedSongAndFetchArt(song: Song | null, whenDoneCallback?: () => void): AppThunk {
    return async (dispatch, _getState) => {
      const token = _getState().user.accessToken;
      let artUri: string | null = null;
      let spotifyUri: string | null = null;

      if (song !== null && token !== null) {
        const result = await fetchArtUri(song, token);
        artUri = result.albumArtUrl;
        spotifyUri = result.trackUrl;
      } else if (song === null && token !== null && _getState().songs.selectedSongHistory.length > 1) {
        const previous = _getState().songs.selectedSongHistory.slice(-2)[0];

        const result = await fetchArtUri(previous, token);
        artUri = result.albumArtUrl;
        spotifyUri = result.trackUrl;
      }

      if (song !== null) {
        dispatch(actions.pushSelectedSong({song, selectedSongArtUri: artUri, spotifyUri}))
      } else {
        dispatch(actions.popSelectedSong({selectedSongArtUri: artUri, spotifyUri}))
      }

      if (whenDoneCallback) {
        whenDoneCallback();
      }
    }
  },
}

function songMatchesSearch(song: Song, search: string): boolean {
  const searchTokens = search.split(' ');

  const toSearch: string[] = [
    ...song.name.split(' '),
    ...song.artists.flatMap((a) => a.name.split(' ')),
  ]

  for (const t1 of searchTokens) {
    let searchTermMatched = false;

    for (const t2 of toSearch) {
      searchTermMatched = searchTermMatched || t2.toLowerCase().includes(t1.toLowerCase());
    }

    if (!searchTermMatched) {
      return false;
    }
  }

  return true;
}

const songs = (state: RootState) => state.songs.songsByGid;
const numberOfSongs = (state: RootState) => Object.keys(state.songs.songRecsByGid).length;
const searchTerm = (state: RootState) => state.songs.currentSearch.trim();

export const selectors = {
    getAllSongs: createSelector([songs, numberOfSongs, searchTerm], (songs, numberOfSongs, searchTerm) => {
      if (searchTerm.length === 0) {
        console.log('Retrieving songs...');

        const result = Object.values(songs);

        console.log(`Got ${result.length} songs.`);

        return result;
      } else {
        console.log('Searching for all songs matching: ' + searchTerm);

        const result = Object.values(songs)
          .filter((s) => songMatchesSearch(s, searchTerm));

        console.log('Done searching songs.');

        return result;
      }
    }),
    getSongsServerSide(state: RootState): Song[] | null {
      return state.songs.searchResults;
    },
    getRecommendations(state: RootState, songGid: string): SongRecommendation[] {
      return state.songs.songRecsByGid[songGid] || [];
    },
    getPlaylist(state: RootState): Song[] | null {
      return state.songs.playlist;
    },
};

export default songsSlice.reducer
import { connect, ResolveThunks } from "react-redux";
import thumbsUp from "./thumbsUp.png";
import thumbsDown from "./thumbsDown.png";
import seed from "./seed.png";
import noGo from "./noGo.svg";
import play from "./play.png";
import TagArray from "../tag_array";
import { rootSelectors, RootState } from "../../state/root_reducer";
import { actions as userActions } from "../../state/slices/user";

interface OwnProps {
    addBorder: boolean;
    trackId: string;
    hasFeedback: boolean;
    onAddFeedback: (trackId: string, type: FeedbackType) => void;
    onPlaySong?: () => void;
}

interface StateProps {
    enabledTags: Tag[];
}

interface DispatchProps {
    onAddTag: typeof userActions.addTag;
    onRemoveTag: typeof userActions.removeTag;
}

type SongActionBarProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

export function SongActionBar(props: SongActionBarProps): JSX.Element {
    const borderClass = props.addBorder ? 'bordered' : ``;
    return (
        <div className={`flex flex-row gap-x-2 ${borderClass}`}>
            <div className="flex flex-row gap-x-2">
                {props.onPlaySong &&
                    <div className="flex items-center">
                        <img className="cursor-pointer w-6 h-6 md:w-7 md:h-7 button-small" onClick={() => props.onPlaySong!()} src={play} alt="Seed" width="30" height="30"/>
                    </div>}
                <div className="flex items-center">
                    <img className="cursor-pointer w-6 h-6 md:w-7 md:h-7 button-small" onClick={() => props.onAddFeedback(props.trackId!, "Seed")} src={seed} alt="Seed" width="30" height="30"/>
                </div>
                <div className="flex items-center">
                    <img className="cursor-pointer w-6 h-6 md:w-7 md:h-7 button-small" onClick={() => props.onAddFeedback(props.trackId!, "Like")} src={thumbsUp} alt="Like" width="30" height="30"/>
                </div>
                <div className="flex items-center">
                    <img className="cursor-pointer w-6 h-6 md:w-7 md:h-7 button-small" onClick={() => props.onAddFeedback(props.trackId!, "Do Not Play")} src={noGo} alt="Do Not Play" width="30" height="30"/>
                </div>
                <div className="flex items-center">
                    <img className="cursor-pointer w-6 h-6 md:w-7 md:h-7 button-small" onClick={() => props.onAddFeedback(props.trackId!, "Dislike")} src={thumbsDown} alt="Dislike" width="30" height="30"/>
                </div>
            </div>
            {props.hasFeedback && <><div className="ml-2 mr-2 border-r-2 border-gray-400"></div>
                <TagArray enabledTags={props.enabledTags} onSelect={(tag) => props.onAddTag(props.trackId, tag)} onDeselect={(tag) => props.onRemoveTag(props.trackId, tag)}/></>
                }
        </div>
    )
}

function mapState(state: RootState, ownProps: OwnProps): StateProps {
    return {
        enabledTags: rootSelectors.user.getEnabledTags(state, ownProps.trackId),
    }
}

const mapDispatch: DispatchProps = {
    onAddTag: userActions.addTag,
    onRemoveTag: userActions.removeTag,
}

export default connect(mapState, mapDispatch) (SongActionBar);
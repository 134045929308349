import { useEffect } from "react";
import { connect, ResolveThunks } from "react-redux";
import SearchableSongList from "../../components/song_list/searchable";
import SongListItemDetail from "../../components/song_list/item/detail";
import { rootSelectors, RootState } from "../../state/root_reducer";
import { actions } from "../../state/slices/songs";
import { LoadingSpinner } from "../../components/loading_spinner";
import useWindowDimensions from "../../components/route_master";

interface StateProps {
    selectedSong: Song | null;
    artUri: string | null;
    spotifyUri: string | null;
    songs: Song[];
    searchResults: Song[] | null;
    searchString: string;
}

interface DispatchProps {
    onFetchAllSongs: typeof actions.fetchAllSongs;
    onFetchRecommendations: typeof actions.fetchRecommendations;
    onUpdateSelectedSongAndFetchArt: typeof actions.updateSelectedSongAndFetchArt;
}

export type SongsPageProps = StateProps & ResolveThunks<DispatchProps>;

export function SongsPage(props: SongsPageProps): JSX.Element {
    const { width } = useWindowDimensions();
    
    useEffect(() => {
        if (props.songs.length === 0) {
            props.onFetchAllSongs();
        }
    }, []);

    function render(): JSX.Element {
        return (
            renderContent()
        );
    }

    function renderContent(): JSX.Element {
        if (props.selectedSong === null) {
            return (
                props.songs?.length === 0  && props.searchString.length === 0 ? 
                    <LoadingSpinner border={false}/> : 
                    <SearchableSongList songs={props.songs} selectSongHandler={props.onUpdateSelectedSongAndFetchArt} initialSearchString={props.searchString} />
            );
        } else {
            return (<SongListItemDetail 
                            song={props.selectedSong}
                            artUri={props.artUri}
                            spotifyUri={props.spotifyUri}
                            fetchRecommendationsHandler={props.onFetchRecommendations}
                            includeScore = {width > 450}/>);
        }
    }

    return render();
}

function mapState(state: RootState): StateProps {
    return {
        searchString: state.songs.currentSearch,
        songs: rootSelectors.songs.getAllSongs(state),
        searchResults: rootSelectors.songs.getSongsServerSide(state),
        selectedSong: state.songs.selectedSongHistory.length > 0 ? state.songs.selectedSongHistory.slice(-1)[0] : null,
        artUri: state.songs.selectedSongArtUri,
        spotifyUri: state.songs.selectedSongSpotifyUri,
    };
}

const mapDispatch: DispatchProps = {
    onFetchAllSongs: actions.fetchAllSongs,
    onFetchRecommendations: actions.fetchRecommendations,
    onUpdateSelectedSongAndFetchArt: actions.updateSelectedSongAndFetchArt,
};

export default connect(mapState, mapDispatch)(SongsPage);

import { useEffect, useRef, useState } from "react";
import { connect, ResolveThunks } from "react-redux";
import SongList from "..";
import { actions } from "../../../state/slices/songs";
import { LoadingSpinner } from "../../loading_spinner";

interface OwnProps {
    songs: Song[];
    selectSongHandler: (song: Song) => void;
    initialSearchString: string;
}

interface DispatchProps {
    onSetSearchString: typeof actions.setSearchString;
    onSearchForMoreResults: typeof actions.searchForMoreResults;
}

export type SearchableSongListProps = OwnProps & ResolveThunks<DispatchProps>;

export function SearchableSongList(props: SearchableSongListProps): JSX.Element {
    const searchTimer = useRef<NodeJS.Timeout | null>(null);
    const [localSearchString, setLocalSearchString] = useState(props.initialSearchString);
    const [isSearching, setIsSearching] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {                    
        props.onSetSearchString(localSearchString, () => setIsSearching(false));
    }, [isSearching, props.onSetSearchString]);

    useEffect(() => {
        if (isAdding) {
            props.onSearchForMoreResults(() => setIsAdding(false));
        }
    }, [isAdding, props.onSearchForMoreResults])

    function render(): JSX.Element {
        return (
            <div className="p-2 sm:p-4 w-full flex flex-col mt-1" style={{overflowY: 'hidden'}}>
                {renderSearch()}
                {(isSearching || isAdding) ? 
                    <LoadingSpinner border={false}/> :
                    <SongList
                        songs={props.songs}
                        otherdata={props.songs[0]?.playlistCount ? props.songs.map((s) => `${s.playlistCount}`) : undefined}
                        otherdataName={props.songs[0]?.playlistCount ? "Playlist count" : undefined}
                        selectSongHandler={props.selectSongHandler}/>}
            </div>
        );
    }

    function renderSearch(): JSX.Element {
        return (
            <div className="mb-4 flex flex-row">
                <div className="flex items-center mr-2">
                    <label className="block text-gray-700 font-bold text-base sm:text-lg ml-1 font-sans">Search (
                        <span className="text-green-700 cursor-pointer button-small" onClick={() => {
                            setIsAdding(true);
                        }}>+</span>):
                    </label>
                </div>
                <div className="flex flex-grow">
                    <input onChange={(evt) => {
                        setLocalSearchString(evt.target.value);

                        if (searchTimer.current !== null) {
                            clearTimeout(searchTimer.current);
                        }

                        searchTimer.current = setTimeout(() => {
                            setIsSearching(true);
                        }, 1000);
                    }} type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                    value={localSearchString}></input>
                </div>
            </div>
        )
    }

    return render();
}

const mapDispatch: DispatchProps = {
    onSetSearchString: actions.setSearchString,
    onSearchForMoreResults: actions.searchForMoreResults,
}

export default connect(undefined, mapDispatch)(SearchableSongList);
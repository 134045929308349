import { useEffect } from "react";
import { connect, ResolveThunks } from "react-redux";
import { LoadingSpinner } from "../../components/loading_spinner";
import { RootState } from "../../state/root_reducer";
import { actions } from "../../state/slices/user";

interface StateProps {
    debugState: string | null;
}

interface DispatchProps {
    onFetchDebug: typeof actions.fetchDebug;
}

export type DebugPageProps = StateProps & ResolveThunks<DispatchProps>;

export function DebugPage(props: DebugPageProps): JSX.Element {    
    useEffect(() => {
        const interval = setInterval(() => {
            props.onFetchDebug();
        }, 5_000)

        return () => clearInterval(interval);
    }, []);

    function render(): JSX.Element {
        return (props.debugState === null ? 
            <LoadingSpinner border={false}/> : 
            <div>{props.debugState}</div>);
    }

    return render();
}

function mapState(state: RootState): StateProps {
    return {
        debugState: state.user.debugState,
    };
}

const mapDispatch: DispatchProps = {
    onFetchDebug: actions.fetchDebug,
};

export default connect(mapState, mapDispatch)(DebugPage);

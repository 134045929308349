import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../state/root_reducer";

interface OwnProps {
    url: string;
}

interface StateProps {
    isLoggedIn: boolean;
}

export type TopNavBarProps = StateProps & OwnProps;

export function TopNavBar(props: TopNavBarProps): JSX.Element {
    function render(): JSX.Element {
        const isSongsPage = props.url.includes('/songs');
        const isFreePlayPage = props.url.includes('/play');
        const isManageLikesPage = props.url.includes('/likes');

        const selectedClass = 'active-tab';
        const nonSelectedClass = 'inactive-tab';

        return (
            <nav className="grid grid-cols-3 sm:w-0 sm:invisible sm:h-0 justify-center h-full">
                <Link to="/songs" className="col-start-1 flex flex-col-reverse">
                    <span className={`pr-1 pl-1 ${isSongsPage ? selectedClass : nonSelectedClass}`}>🔍</span>
                </Link>
                {props.isLoggedIn && 
                    <Link to="/play" className="col-start-2 flex flex-col-reverse">
                        <span className={`pl-1 pr-1 ${isFreePlayPage ? selectedClass : nonSelectedClass}`}>▶</span>
                    </Link>}
                {props.isLoggedIn && 
                    <Link to="/likes" className="col-start-3 flex flex-col-reverse">
                        <span className={`pl-1 pr-1 ${isManageLikesPage ? selectedClass : nonSelectedClass}`}>👍</span>
                    </Link> }
            </nav>
        );
    }

    return render();
}

function mapState(state: RootState): StateProps {
    return {
        isLoggedIn: state.user.currentUser?.id !== undefined,
    }
}

export default connect(mapState)(TopNavBar);
import { connect } from "react-redux";
import { noSleep } from "../../../var";
import { RootState } from "../../../state/root_reducer";
import { formatDuration } from "../../../util";
import './styles.css';

interface StateProps {
    token: string | null;
}

interface OwnProps {
    song: Song;
    otherdata?: string | JSX.Element;
    index: number;
    selectSongHandler: (song: Song) => void;
    deleteSongHandler?: (song: Song) => void;
    overridePlayHandler?: (song: Song, selected: boolean) => void;
    addStartingAtSong: (song: Song) => void;
    currentlyPlaying: boolean;
    currentlySelected?: boolean;
}

export type SongListItemProps = StateProps & OwnProps;

export function SongListItem(props: SongListItemProps): JSX.Element {

  function handleAddToPlaylistClick(evt: React.MouseEvent): void {
    noSleep.enable();
    
    evt.stopPropagation();

    props.addStartingAtSong(props.song)
  }

  function handleDelete(evt: React.MouseEvent): void {
    evt.stopPropagation();

    props.deleteSongHandler!(props.song);
  }

  function render(): JSX.Element {    
    const cols = props.otherdata ? 4 : 3;
    const evenOdds = props.index % 2 === 0 ? "row-even" : "row-odd";

    return (
        <div className={props.currentlyPlaying ? "selected-song" : ""}>
            <div className={`grid grid-cols-${cols} p-1 ${evenOdds} pl-1 cursor-pointer border-gray-400`} onClick={() => props.selectSongHandler(props.song)}>
                <div className="col-start-1 pr-1 flex items-center">
                    <span className="text-xs sm:text-sm md:text-base text-left break-words block text-gray-700 ml-1">{props.song.name}</span>
                </div>
                <div className="col-start-2 pr-1 flex items-center">
                    <span className="text-xs sm:text-sm md:text-base text-left break-words block text-gray-700">{props.song.artists.map((artist) => artist.name).join(', ')}</span>
                </div>
                <div className="col-start-3 pr-1 flex items-center">
                    <span className="text-xs sm:text-sm md:text-base text-left break-words block text-gray-700">{formatDuration(props.song.duration)}</span>
                </div>
                {props.otherdata &&
                    <div className="col-start-4 flex items-center">
                        {typeof props.otherdata === "string" ? <span className="text-xs sm:text-sm md:text-base text-left break-words block text-gray-700">{props.otherdata}</span> : props.otherdata}
                    </div>
                }
                
                <span className={`col-start-5 right-0 text-right block text-gray-700 text-bold pr-2 mb-1 flex items-center`}>
                {props.deleteSongHandler &&
                    <span className="text-xs sm:text-sm md:text-base border border-black rounded pl-1 pr-1 button-small mr-2" onClick = {handleDelete}>
                        🗑
                    </span>
                    }
                    {(!props.overridePlayHandler && props.song.spotify_id) && <span className="text-xs sm:text-sm md:text-base border border-black rounded pl-1 pr-1 button-small" onClick = {(evt) =>  handleAddToPlaylistClick(evt)}>
                        ▶
                    </span>}
                    {(!props.overridePlayHandler && !props.song.spotify_id) && <span className="invisible text-xs sm:text-sm md:text-base border border-black rounded pl-1 pr-1 button-small">
                        ▶
                    </span>}
                    {props.overridePlayHandler && 
                        <input className="bg-gray-100" type="checkbox" checked={props.currentlySelected} onChange={(evt) => props.overridePlayHandler!(props.song, (evt.target as HTMLInputElement).checked)}/>}
                </span>
            </div>
            
        </div>
    );
  } 

  return render();
}

function mapState(state: RootState): StateProps {
    return {
        token: state.user.accessToken,
    }
}

export default connect(mapState) (SongListItem);
import { connect, ResolveThunks } from "react-redux";
import { actions } from "../../state/slices/user";
import { RootState } from "../../state/root_reducer";
import { useNavigate } from "react-router";

interface StateProps {
    loggedInUserId: string | null;
}

interface DispatchProps {
    onArchiveUser: typeof actions.archiveUser;
}

type Props = StateProps &  ResolveThunks<DispatchProps>;

const PrivacyPage = (props: Props): JSX.Element => {
    const oddRow = "p-1";
    const evenRow = "bg-gray-300 p-1";
    const navigate = useNavigate();
    return (
        <div className="p-4 font-bold text-gray-700 flex flex-col gap-y-2">
            <span className={`block text-gray-700 font-bold text-base sm:text-lg font-sans p-1 border-b-2`}>
                Privacy, etc:
            </span>
            <p className={oddRow}>
                Vybe is a free tool for helping find new Music!
            </p>
            <p className={evenRow}>
                We use a proprietary engine to recommend songs based on feedback you give us about songs you like.
            </p>
            <p className={oddRow}>
                We use Spotify's Developer API to search for songs, display album art, and to play songs.
            </p>
            <p className={evenRow}>
                A Premium Spotify subscription is required to use most features.
            </p>
            <p className={oddRow}>
                The only data we collect is the feedback you provide us about specific songs: seeds, likes, and dislikes. 
            </p>
            <p className={evenRow}>
                We will never sell, disclose, or otherwise distribute any of your data. 
            </p>
            <p className={oddRow}>
                We will disconnect your account and delete all of your data upon request, using the button below:
            </p>
            {props.loggedInUserId ? 
                <div className="flex">
                    <div className="flex flex-shrink button-generic border-red cursor-pointer">
                        <span className="text-red-800" onClick={() => props.onArchiveUser(props.loggedInUserId!, navigate)}>
                            Delete Account
                        </span>
                    </div>
                </div> : null}
        </div>
    );
} 

function mapState(state: RootState): StateProps {
    return {
        loggedInUserId: state.user.currentUser?.id ?? null,
    };
}

const mapDispatch: DispatchProps = {
    onArchiveUser: actions.archiveUser,
};

export default connect(mapState, mapDispatch)(PrivacyPage);
import NoSleep from 'nosleep.js';

export const noSleep = new NoSleep();

const PROD = process.env.NODE_ENV !== 'development';

const LOCALHOST = 'http://localhost:3000';
const PROD_HOST = 'https://vybe.link';

const LOCAL_API = 'http://localhost:8080'
const PROD_API_HOST = 'https://vybe.link';

export const HOST = PROD ? PROD_HOST : LOCALHOST;
export const API_HOST = PROD ? PROD_API_HOST : LOCAL_API; 